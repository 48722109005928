import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'

import Layout from "../components/layout"

export const BlogPage = ({ data: { site: { siteMetadata: { title, siteUrl } }, allMarkdownRemark }, location: { pathname }}) => {
  return (
    <Layout>
      <Helmet
        title={`Blog | ${title}`}
        meta={[
          { property: 'og:title', content: `Blog | ${title}` },
          { property: 'og:url', content: `${siteUrl}${pathname}` },
        ]}
      />

      <h1 className="text-4xl font-bold w-2/3 leading-none mb-4">Blog</h1>

      {allMarkdownRemark.edges.map(
        ({ node: { frontmatter: { path, title, summary, date } } }) => (
          <Link key={path} to={path} className="block mb-4">
            <h4 className="text-lg font-medium text-blue-500 leading-tight">{title}</h4>
            <div className="text-md">{summary}</div>
            <div className="text-sm italic text-gray-700 font-thin">Published {date}</div>
          </Link>
        )
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            path
            tags
            summary
            title
          }
        }
      }
    }
  }
`;

export default BlogPage;
